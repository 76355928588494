import { rootClasses } from '../../formkit.theme.mjs';
import { genesisIcons } from '@formkit/icons';
import { createInput } from '@formkit/vue';
import YesNoButtons from '@/Components/FormKit/YesNoButtons.vue';

const config = {
    config: {
        rootClasses,
    },
    inputs: {
        yesnobuttons: createInput(YesNoButtons, {
            props: ['key'],
        }),
    },
    icons: {
        ...genesisIcons,
    },
};

export default config;
