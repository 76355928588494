import './bootstrap';
import '../css/app.css';

// Import modules...
import { createApp, h, watch } from 'vue';
import { createInertiaApp, Head, Link } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';

import { plugin as FormKitPlugin, defaultConfig as defineFormKitConfig } from '@formkit/vue';
import { createProPlugin, inputs } from '@formkit/pro';
import FormKitConfig from './formkit.config.js';

import * as Sentry from '@sentry/vue';

import { useIdle } from '@vueuse/core';

import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/vue.m';

const appName = 'RiskAdvisor';

const progressColor = '#4B5563';

createInertiaApp({
    title: title => `${title} - ${appName}`,
    resolve: name => resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue')),
    progress: { color: progressColor },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) });

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_VUE_DSN,
            environment: import.meta.env.VITE_APP_ENV,
            integrations: [Sentry.replayIntegration()],

            // Set tracesSampleRate to 1.0 to capture 100%
            // of transactions for performance monitoring.
            // We recommend adjusting this value in production
            tracesSampleRate: 1.0,

            // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: ['localhost', /^https:\/\/app\.riskadvisor\.insure\//],

            // Capture Replay for 10% of all sessions,
            // plus for 100% of sessions with an error
            replaysSessionSampleRate: 0.1,
            replaysOnErrorSampleRate: 1.0,
        });

        const formKitPro = createProPlugin(import.meta.env.VITE_FORMKIT_PRO_KEY, inputs);

        app.component('InertiaHead', Head)
            .component('InertiaLink', Link)
            .use(
                FormKitPlugin,
                defineFormKitConfig({
                    ...FormKitConfig,
                    plugins: [formKitPro],
                }),
            )
            .use(ZiggyVue)
            .use(plugin)
            .mount(el);

        const { idle } = useIdle(import.meta.env.VITE_SESSION_LIFETIME * 60 * 1000);

        watch(idle, idleValue => {
            if (!idleValue) {
                return;
            }

            if (window.location.pathname === '/login') {
                return;
            }

            alert('You are being signed out due to inactivity. Please sign in again to continue');

            app.config.globalProperties.$inertia.post('/logout').catch(error => {
                if (error.response && error.response.status === 419) {
                    window.location.href = '/login';
                }
            });
        });

        return app;
    },
});
